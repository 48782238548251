import PropTypes from "prop-types";

export const BaseFilterSwitch = ({
	options,
	selected,
	onChange,
	styles,
	...props
}) => (
	<div className={styles.buttonContainer}>
		{options.map(({ value, label }) => (
			<button
				key={value}
				value={value}
				onClick={() => onChange(value)}
				className={`${styles.button} ${selected === value ? styles.selected : styles.default}`}
				{...props}
			>
				{label}
			</button>
		))}
	</div>
);

BaseFilterSwitch.propTypes = {
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string.isRequired,
			label: PropTypes.string.isRequired,
		}),
	).isRequired,
	selected: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	styles: PropTypes.object.isRequired,
};
