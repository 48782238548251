// import constants
import { FVY_DOMAIN, IR_DOMAIN, SITE, TCR_DOMAIN } from "@/config/constants";
import * as tcrConstants from "@/config/site/thecreditreview";
// import selectors
import * as selectors from "@/selectors";
import PropTypes from "prop-types";
import { path } from "ramda";
import { useSelector } from "react-redux";
import { css } from "twin.macro";

const styles = {
	filteringSection: {
		baseStyles: css`
			width: 100%;
			background-color: #fff;
			position: relative;
			box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.07);
		`,
		domainLevelOverrides: {
			[IR_DOMAIN]: {
				allVerticals: css`
					@media (max-width: 640px) {
						margin-top: 0;
						width: auto;
						height: 36px;
						background-color: transparent;
						box-shadow: none;
					}
				`,
			},
			[TCR_DOMAIN]: {
				allVerticals: css`
					margin-top: -22px;

					@media (max-width: 640px) {
						margin-top: 0;
						width: auto;
						height: 36px;
						background-color: transparent;
						box-shadow: none;
					}
				`,
			},
		},
	},
	filteringContainer: {
		domainLevelOverrides: {
			[FVY_DOMAIN]: {
				allVerticals: css`
					background-color: #fff;
					border-radius: 5px;

					@media (max-width: 640px) {
						background-color: #ff5161;
						border-radius: 28px;
						box-shadow: none;
					}
				`,
			},
			[IR_DOMAIN]: {
				allVerticals: css`
					background-color: #fff;
					border-radius: 5px;

					@media (max-width: 640px) {
						background-color: #ff5161;
						border-radius: 28px;
						box-shadow: none;
					}
				`,
			},
			[TCR_DOMAIN]: {
				allVerticals: css`
					background-color: #fff;
					border-radius: 5px;

					@media (max-width: 640px) {
						background-color: #ff5161;
						border-radius: 28px;
						box-shadow: none;
					}
				`,

				[tcrConstants.BUSINESS_LOANS_DEFAULT_PATH]: css`
					margin-bottom: none;
					position: relative;
					top: 30px;
					box-shadow: 2px 1px 20px #d3d3d3;
					width: 1110px;
					padding-left: 0;
					padding-right: 0;

					@media (max-width: 1199px) {
						width: 910px;
					}

					@media (max-width: 991px) {
						width: 100%;
						max-width: 690px;
					}

					@media (max-width: 767px) {
						max-width: 500px;
						top: 24px;
						margin-bottom: 40px;
					}

					@media (max-width: 640px) {
						top: 0;
						margin-bottom: 0;
						max-width: 100%;
						padding-right: 15px;
						padding-left: 15px;
						margin-right: auto;
						margin-left: auto;
					}
				`,
				[tcrConstants.PERSONAL_LOANS_DEFAULT_PATH]: css`
					margin-bottom: none;
					position: relative;
					top: 30px;
					box-shadow: 2px 1px 20px #d3d3d3;
					width: 1110px;
					padding-left: 0;
					padding-right: 0;

					@media (max-width: 1199px) {
						width: 910px;
					}

					@media (max-width: 991px) {
						width: 100%;
						max-width: 690px;
					}

					@media (max-width: 767px) {
						max-width: 500px;
						top: 24px;
						margin-bottom: 40px;
					}

					@media (max-width: 640px) {
						top: 0;
						margin-bottom: 0;
						max-width: 100%;
						padding-right: 15px;
						padding-left: 15px;
						margin-right: auto;
						margin-left: auto;
					}
				`,
				[tcrConstants.AUTO_REFINANCE_PATH]: css`
					margin-bottom: none;
					position: relative;
					top: 30px;
					box-shadow: 2px 1px 20px #d3d3d3;
					width: 1110px;
					padding-left: 0;
					padding-right: 0;

					@media (max-width: 1199px) {
						width: 910px;
					}

					@media (max-width: 991px) {
						width: 100%;
						max-width: 690px;
					}

					@media (max-width: 767px) {
						max-width: 500px;
						top: 24px;
						margin-bottom: 40px;
					}

					@media (max-width: 640px) {
						top: 0;
						margin-bottom: 0;
						max-width: 100%;
						padding-right: 15px;
						padding-left: 15px;
						margin-right: auto;
						margin-left: auto;
					}
				`,
				[tcrConstants.STUDENT_LOANS_REFINANCE_DEFAULT_PATH]: css`
					margin-bottom: none;
					position: relative;
					top: 30px;
					box-shadow: 2px 1px 20px #d3d3d3;
					width: 1110px;
					padding-left: 0;
					padding-right: 0;

					@media (max-width: 1199px) {
						width: 910px;
					}

					@media (max-width: 991px) {
						width: 100%;
						max-width: 690px;
					}

					@media (max-width: 767px) {
						max-width: 500px;
						top: 24px;
						margin-bottom: 40px;
					}

					@media (max-width: 640px) {
						top: 0;
						margin-bottom: 0;
						max-width: 100%;
						padding-right: 15px;
						padding-left: 15px;
						margin-right: auto;
						margin-left: auto;
					}
				`,
			},
		},
	},
};

const FilteringSection = (props) => {
	const vertical = useSelector(selectors.getVertical);

	return (
		<div
			id="filteringSection"
			css={[
				styles.filteringSection.baseStyles,
				path(
					["filteringSection", "domainLevelOverrides", SITE, "allVerticals"],
					styles,
				),
				path(
					["filteringSection", "domainLevelOverrides", SITE, vertical],
					styles,
				),
			]}
		>
			<div
				css={[
					path(
						[
							"filteringContainer",
							"domainLevelOverrides",
							SITE,
							"allVerticals",
						],
						styles,
					),
					path(
						["filteringContainer", "domainLevelOverrides", SITE, vertical],
						styles,
					),
				]}
				className="container"
			>
				{props.children}
			</div>
		</div>
	);
};

export default FilteringSection;

FilteringSection.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
};
