import { IR_DOMAIN, SITE } from "@/config/constants";
import { getVertical } from "@/selectors";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { path } from "ramda";
import { useSelector } from "react-redux";

import { DISABILITY_INSURANCE_DEFAULT_PATH } from "../../../config/site/insuranceranked";
import Filters from "./Filters";

const styles = {
	wrapper: {
		verticalOverrides: {
			[IR_DOMAIN]: {
				[DISABILITY_INSURANCE_DEFAULT_PATH]: css`
					@media (max-width: 991px) {
						background-color: #f5f8fa;
					}
				`,
			},
		},
	},
	container: {
		verticalOverrides: {
			[IR_DOMAIN]: {
				[DISABILITY_INSURANCE_DEFAULT_PATH]: css`
					margin-top: 10px;
					display: flex;
					align-items: center;
					background-clip: content-box;
					background-color: #f5f8fa;

					@media (max-width: 1199px) {
						padding: 0 30px;
					}

					@media (max-width: 991px) {
						margin-top: 0;
					}

					@media (max-width: 767px) {
						max-width: 500px;
						margin-left: auto;
						margin-right: auto;
						padding: 0;
					}

					> div {
						margin: 7px 10px 7px 10px !important;
					}
				`,
			},
		},
	},
};

const FixedTopFilters = ({ buildFilteringState, isNotUserFiltered }) => {
	const vertical = useSelector(getVertical);
	return (
		<div
			css={[
				path(["wrapper", "baseStyles", SITE], styles),
				path(["wrapper", "verticalOverrides", SITE, vertical], styles),
			]}
		>
			<div
				className="container"
				css={[
					path(["container", "baseStyles", SITE], styles),
					path(["container", "verticalOverrides", SITE, vertical], styles),
				]}
			>
				<Filters
					isMobile={false}
					buildFilteringState={buildFilteringState}
					disabled={isNotUserFiltered}
				/>
			</div>
		</div>
	);
};

export default FixedTopFilters;

FixedTopFilters.propTypes = {
	buildFilteringState: PropTypes.func.isRequired,
	isNotUserFiltered: PropTypes.bool.isRequired,
	isMobile: PropTypes.bool,
};
