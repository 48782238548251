import { useEffect, useState } from "react";
import { IR_DOMAIN, SITE, TCR_DOMAIN } from "@/config/constants";
import * as irConstants from "@/config/site/insuranceranked";
import * as tcrConstants from "@/config/site/thecreditreview";
import { getFilterPlaceholder } from "@/helpers";
import { getIsNotModalFiltered, getModalSelection } from "@/selectors";
import { css } from "@emotion/react";
import { InputLabel, NativeSelect as Select } from "@mui/material";
import PropTypes from "prop-types";
import { path } from "ramda";
import { useSelector } from "react-redux";

const styles = {
	selectWrapper: {
		baseStyles: css`
			display: flex;
			width: 100%;
			justify-content: space-between;
			align-items: center;
			border: 1px solid #d6dbde;
			border-radius: 3px;
			padding: 0 6px 0 48px;
			position: relative;
		`,
		domainLevelOverrides: {
			[TCR_DOMAIN]: {
				[tcrConstants.PERSONAL_LOANS_DEFAULT_PATH]: css`
					@media (max-width: 991px) {
						flex-wrap: wrap;
						padding: 5px 6px 6px 48px;
					}

					@media (max-width: 767px) {
						padding: 5px 6px 6px 44px;
					}

					@media (max-width: 640px) {
						padding: 13px 6px 11px 44px;
					}
				`,
			},
		},
	},
	label: {
		baseStyles: css`
			font-family: AvenirNextLTPro-Demi;
			font-weight: 600;
			text-transform: capitalize;
			color: #000;
			margin-bottom: 0;
			font-size: 14px;
			background-color: #fff;
			margin-right: 15px;
			position: static;
			overflow: visible;
			text-overflow: unset;

			:before {
				content: "";
				position: absolute;
				width: 26px;
				height: 26px;
				background-repeat: no-repeat;
				background-size: auto;
			}
		`,
		domainLevelOverrides: {
			[TCR_DOMAIN]: {
				allVerticals: css`
					:before {
						top: 6px;
						left: 12px;
						background-image: none;
					}
				`,
				[tcrConstants.BUSINESS_LOANS_DEFAULT_PATH]: {
					[tcrConstants.FUNDING_TYPE_FILTERING_KEY]: css`
						:before {
							top: 9px;
							left: 10px;
							background-image: url("/react-images/thecreditreview/fundingType.svg");
						}
					`,
					[tcrConstants.MONTHLY_REVENUE_FILTERING_KEY]: css`
						:before {
							background-image: url("/react-images/thecreditreview/monthlyRevenue.svg");
						}
					`,
				},
				[tcrConstants.PERSONAL_LOANS_DEFAULT_PATH]: {
					[tcrConstants.CREDIT_QUALITY_FILTERING_KEY]: css`
						:before {
							background-image: url("/react-images/thecreditreview/creditScoreIcon.svg");
						}
					`,
					[tcrConstants.LOAN_AMOUNT_FILTERING_KEY]: css`
						:before {
							background-image: url("/react-images/thecreditreview/loanIcon.svg");
						}
					`,
				},
				[tcrConstants.AUTO_REFINANCE_PATH]: {
					[tcrConstants.CREDIT_QUALITY_FILTERING_KEY]: css`
						:before {
							background-image: url("/react-images/thecreditreview/creditScoreIcon.svg");
						}
					`,
					[tcrConstants.LOAN_AMOUNT_FILTERING_KEY]: css`
						:before {
							background-image: url("/react-images/thecreditreview/loanIcon.svg");
						}
					`,
				},
				[tcrConstants.STUDENT_LOANS_REFINANCE_DEFAULT_PATH]: {
					[tcrConstants.CREDIT_QUALITY_FILTERING_KEY]: css`
						:before {
							background-image: url("/react-images/thecreditreview/creditScoreIcon.svg");
						}
					`,
					[tcrConstants.LOAN_AMOUNT_FILTERING_KEY]: css`
						:before {
							background-image: url("/react-images/thecreditreview/loanIcon.svg");
						}
					`,
				},
			},
			[IR_DOMAIN]: {
				[irConstants.DISABILITY_INSURANCE_DEFAULT_PATH]: {
					[irConstants.POLICY_TYPE_FILTERING_KEY]: css`
						:before {
							top: 3px;
							left: 14px;
							height: 32px;
							width: 32px;
							background-image: url("/react-images/thecreditreview/healthShield.svg");
						}
					`,
				},
			},
		},
	},
	materialSelectElement: {
		base: css`
			background-color: inherit;
			color: #000;
			font-size: 16px;
			line-height: inherit;

			@media (max-width: 640px) {
				max-width: 164px;
			}

			div {
				width: 100%;
				position: relative;
			}

			.MuiNativeSelect-select {
				font-family: AvenirNextLTPro-Demi;
				font-size: 14px;
				color: #000;
				background-color: #fff;
				line-height: 1;
				width: 100%;
				cursor: pointer;
				min-width: 16px;
				user-select: none;

				:focus {
					background: none;
				}
			}

			svg {
				color: #1d8ce0;
			}

			:before {
				border: none !important;
			}

			:after {
				border: none !important;
			}
		`,
	},
};

export const FilterSelect = ({
	filter,
	vertical,
	isNotUserFiltered,
	filteringKey,
	title,
	options,
	buildFilteringState,
	isMobile,
}) => {
	const [selectedOption, setSelectedOption] = useState("default");
	const isNotModalFiltered = useSelector(getIsNotModalFiltered);
	const modalSelectedOption = useSelector((state) =>
		getModalSelection(state, { filteringKey }),
	);

	useEffect(() => {
		// if isNotModalFiltered = false and isNotUserFiltered = true update the state selectedOption to be the modalSelectedOption
		if (!isNotModalFiltered && !isNotUserFiltered) {
			setSelectedOption(
				typeof modalSelectedOption === "object" &&
					"value" in modalSelectedOption
					? modalSelectedOption.value
					: modalSelectedOption,
			);
		}
	}, [isNotModalFiltered, isNotUserFiltered, modalSelectedOption]);

	const handleChange = (event) => {
		const { value } = event.target;
		const ranges = options.map((option) => option.range);
		const rangeIndex = ranges.findIndex(
			(range) =>
				range && range[0] <= Number(value) && range[1] >= Number(value),
		);

		const filteringStateValue =
			rangeIndex !== -1 ? { value, range: ranges[rangeIndex] } : value;

		setSelectedOption(value);
		buildFilteringState({ [filteringKey]: filteringStateValue }, isMobile);
	};

	/*
		isNotUserFiltered is a boolean indicating whether or not the user has applied filtering via the FilterSelect component or FilterInput component and clicked the 'Apply Filters' button.
		Once the 'Apply Filters' button has been pressed isNotUserFiltered = false
	*/
	const userSelectedOption = isNotUserFiltered ? "default" : selectedOption;

	/* Boolean indicating whether or not the user has applied filtering via UI filtering with the 'Apply Filters' button and also applied filtering via the Modal */
	const isModalAndUserFiltered = !isNotUserFiltered && !isNotModalFiltered;

	/*
		isNotModalFilteredOrIsModalAndUserFiltered is "true" when the user hasn't applied modal filtering, or applied both modal and UI filtering via 'Apply Filters'.
		This is necessary as we need a way to update the selected option when the user has applied both modal and UI filtering as we're not simply changing the placeholder when the user selects an option in the modal.
	*/
	const isNotModalFilteredOrIsModalAndUserFiltered =
		isNotModalFiltered || isModalAndUserFiltered;

	const finalSelectedOption = isNotModalFilteredOrIsModalAndUserFiltered
		? userSelectedOption
		: modalSelectedOption;

	const placeholder = getFilterPlaceholder(vertical, filter, filteringKey);

	return (
		<div
			css={[
				styles.selectWrapper.baseStyles,
				path(
					["selectWrapper", "domainLevelOverrides", SITE, "allVerticals"],
					styles,
				),
				path(["selectWrapper", "domainLevelOverrides", SITE, vertical], styles),
			]}
		>
			<InputLabel
				htmlFor={`${filteringKey}_Input`}
				css={[
					styles.label.baseStyles,
					path(["label", "domainLevelOverrides", SITE, "allVerticals"], styles),
					path(
						["label", "domainLevelOverrides", SITE, vertical, filteringKey],
						styles,
					),
					vertical === tcrConstants.STUDENT_LOANS_REFINANCE_DEFAULT_PATH &&
						filteringKey === tcrConstants.LOAN_AMOUNT_FILTERING_KEY &&
						finalSelectedOption === "50000" &&
						css`
							width: min-content;
						`,
				]}
			>
				{title}
			</InputLabel>
			<Select
				native="true"
				css={[
					styles.materialSelectElement.base,
					path(["materialSelectElement", vertical], styles),
				]}
				onChange={handleChange}
				value={finalSelectedOption}
				id={`${filteringKey}_Input`}
			>
				<option value="default" disabled>
					{placeholder}
				</option>
				{options.map((option, idx) => (
					<option key={idx} value={option.value}>
						{option.label}
					</option>
				))}
			</Select>
		</div>
	);
};

FilterSelect.propTypes = {
	filter: PropTypes.string,
	vertical: PropTypes.string.isRequired,
	isNotUserFiltered: PropTypes.bool.isRequired,
	filteringKey: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	options: PropTypes.array.isRequired,
	buildFilteringState: PropTypes.func.isRequired,
	isMobile: PropTypes.bool.isRequired,
};
