import { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { AGE_THRESHOLD } from "../../../config/constants";
import {
	AGED_FILTERING_KEY,
	FILTERING_AGE_VALUE,
} from "../../../config/site/insuranceranked";
import { AgeToggleFilterCalenderIcon } from "../../client/assets/icons/insuranceranked/AgeToggleFilterCalenderIcon";
import { AgeToggleFilterSelectedIcon } from "../../client/assets/icons/insuranceranked/AgeToggleFilterSelectedIcon";
import { getIsNotModalFiltered, getModalSelection } from "../../selectors";
import {
	deleteFilteringProperty,
	setFilteringState,
	setFilteringStateWithoutTransition,
} from "../../store/filtering/actions";
import {
	BaseToggleButton,
	BaseToggleButtonGroup,
} from "../BaseComponents/BaseToggleButtonGroup";

const useFilterToggle = () => {
	const [value, setValue] = useState(false);
	const [valueChanged, setValueChanged] = useState(false);

	const dispatch = useDispatch();
	const modalSelectedOption = useSelector((state) =>
		getModalSelection(state, { filteringKey: AGED_FILTERING_KEY }),
	);
	const isModalFiltered = useSelector((state) => !getIsNotModalFiltered(state));

	const handleChange = useCallback(
		(newValue) => {
			setValue(newValue);
			setValueChanged(true);

			if (newValue) {
				dispatch(
					setFilteringStateWithoutTransition({
						[AGE_THRESHOLD]: FILTERING_AGE_VALUE,
					}),
				);
			} else {
				dispatch(deleteFilteringProperty(AGE_THRESHOLD));
			}

			dispatch(
				setFilteringState({
					[AGED_FILTERING_KEY]: newValue,
					isNotUserFiltered: false,
				}),
			);
		},
		[dispatch],
	);

	// use modalValue if modalFiltered and user didn't make a change in the UI
	const toggleButtonValue = useMemo(
		() => (isModalFiltered && !valueChanged ? modalSelectedOption : value),
		[isModalFiltered, valueChanged, modalSelectedOption, value],
	);

	return {
		handleChange,
		toggleButtonValue,
	};
};

export const LifeInsuranceFilter = () => {
	const { handleChange, toggleButtonValue } = useFilterToggle();

	return (
		<div className="tw-bg-[#F5F8FA]">
			<div className="tw-mx-auto tw-px-[15px] tw-py-4 sm:tw-max-w-[720px] sm:tw-py-3.5 desktop:tw-max-w-[960px] lg2:tw-max-w-[1140px]">
				<div className="tw-mx-auto tw-flex tw-w-full tw-max-w-[320px] tw-flex-col tw-items-center tw-gap-4 sm:tw-max-w-fit sm:tw-flex-row md:tw-mx-0">
					<div className="tw-flex tw-items-center tw-gap-3">
						<AgeToggleFilterCalenderIcon className="tw-text-[32px] tw-text-[#2299DC]" />
						<span className="tw-text-xl tw-font-bold tw-leading-8 tw-tracking-[-0.2px] tw-text-[#232227]">
							{`Are you ${FILTERING_AGE_VALUE} or Older?`}
						</span>
					</div>
					<BaseToggleButtonGroup
						value={toggleButtonValue}
						onChange={handleChange}
						aria-label="Age filter toggle"
						className="tw-inline-flex tw-w-full tw-gap-1 tw-overflow-hidden tw-rounded-full tw-p-1.5 tw-ring-1 tw-ring-inset tw-ring-[#D4D4D8] sm:tw-w-auto sm:tw-justify-center"
					>
						<ToggleOption
							value={true}
							label="Yes"
							isSelected={toggleButtonValue === true}
							ageValue={FILTERING_AGE_VALUE}
						/>
						<ToggleOption
							value={false}
							label="No"
							isSelected={toggleButtonValue === false}
							ageValue={FILTERING_AGE_VALUE}
						/>
					</BaseToggleButtonGroup>
				</div>
			</div>
		</div>
	);
};

const ToggleOption = ({ value, label, isSelected, ageValue, ...props }) => {
	const buttonClasses = `tw-inline-flex tw-flex-1 tw-items-center tw-justify-center tw-cursor-pointer tw-select-none tw-appearance-none tw-gap-1 tw-whitespace-nowrap tw-rounded-full tw-border-none tw-py-2 tw-pl-2 tw-pr-3 tw-align-middle tw-text-base tw-font-semibold tw-leading-4 tw-outline-none tw-transition-all tw-ease-in-out ${
		isSelected
			? "tw-bg-[#2299DC] tw-text-white hover:tw-bg-blue-600"
			: "tw-bg-transparent tw-text-[#535B62] hover:tw-bg-gray-300"
	}`;

	const spanClasses = `tw-inline-flex tw-size-4 tw-items-center tw-justify-center tw-rounded-full tw-transition-all tw-ease-in-out ${
		isSelected ? "tw-bg-white" : "tw-ring-2 tw-ring-inset tw-ring-current"
	}`;

	const iconClasses = `tw-text-[8px] tw-text-[#2299DC] tw-transition-all tw-ease-in-out ${
		isSelected ? "tw-opacity-100" : "tw-opacity-0"
	}`;

	return (
		<BaseToggleButton
			value={value}
			aria-label={`${label}, I'm ${value ? `${ageValue} or older` : `under ${ageValue}`}`}
			className={buttonClasses}
			{...props}
		>
			<span className={spanClasses}>
				<AgeToggleFilterSelectedIcon className={iconClasses} />
			</span>
			<span>{label}</span>
		</BaseToggleButton>
	);
};

ToggleOption.propTypes = {
	value: PropTypes.bool.isRequired,
	label: PropTypes.string.isRequired,
	isSelected: PropTypes.bool.isRequired,
	ageValue: PropTypes.number.isRequired,
};
