import { useSelector } from "react-redux";

import { FVY_DOMAIN, IR_DOMAIN, SITE } from "../../../config/constants";
import { HAIR_GROWTH_PATH, MORTGAGE_PATH } from "../../../config/site/favy";
import { IR_LIFE_INSURANCE_DEFAULT_PATH } from "../../../config/site/insuranceranked";
import { getVertical } from "../../selectors";
import { HairGrowthFilter } from "./HairGrowthFilter";
import { LifeInsuranceFilter } from "./LifeInsuranceFilter";
import { MortgageFilter } from "./MortgageFilter";

export const IndividualVerticalFilteringContainer = () => {
	const vertical = useSelector(getVertical);

	// This can go into vertical config like grid.js
	if (SITE === FVY_DOMAIN) {
		if (vertical === HAIR_GROWTH_PATH) return <HairGrowthFilter />;
		if (vertical === MORTGAGE_PATH) return <MortgageFilter />;

		return null;
	}

	if (SITE === IR_DOMAIN) {
		if (vertical === IR_LIFE_INSURANCE_DEFAULT_PATH)
			return <LifeInsuranceFilter />;
	}

	return null;
};
