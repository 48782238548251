import { useEffect } from "react";
import { SET_FEATURED_COMPANY } from "@/config/constants";
import {
	getIsAmazonVertical,
	getShowFeaturedCompany,
	getVertical,
} from "@/selectors";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import { getGridLayoutForVertical } from "../../../config/grid";
import { AmazonProduct } from "../Verticals/Amazon/AmazonProduct";
import { CompanyContext } from "./CompanyContext";
import { LegacyFeaturedCompany } from "./LegacyFeaturedCompany";

export const FeaturedCompany = ({ companies, innerWidth }) => {
	const featuredCompany = companies[0];
	const showFeaturedCompany = useSelector(getShowFeaturedCompany);
	const dispatch = useDispatch();
	const vertical = useSelector(getVertical);
	const isAmazonVertical = useSelector(getIsAmazonVertical);

	useEffect(() => {
		if (showFeaturedCompany) {
			dispatch({
				type: SET_FEATURED_COMPANY,
				payload: { company: featuredCompany },
			});
		}
	}, [featuredCompany]);

	const configLayout = getGridLayoutForVertical(vertical);
	const gridLayoutForVertical = isAmazonVertical ? AmazonProduct : configLayout;
	if (showFeaturedCompany && gridLayoutForVertical) {
		const GridLayout = gridLayoutForVertical;

		return (
			<CompanyContext.Provider
				value={{
					company: featuredCompany,
					companyIndex: 1,
					isFeaturedCompany: true,
				}}
			>
				<GridLayout isFeaturedCompany={true} />
			</CompanyContext.Provider>
		);
	}

	return (
		<CompanyContext.Provider
			value={{ company: featuredCompany, companyIndex: 1 }}
		>
			<LegacyFeaturedCompany companies={companies} innerWidth={innerWidth} />
		</CompanyContext.Provider>
	);
};

FeaturedCompany.propTypes = {
	innerWidth: PropTypes.number.isRequired,
	companies: PropTypes.array.isRequired,
};
