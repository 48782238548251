import { Children, cloneElement } from "react";
import PropTypes from "prop-types";

export const BaseToggleButtonGroup = ({
	children,
	value,
	onChange,
	...props
}) => {
	const handleChange = (newValue) => {
		onChange(newValue);
	};

	return (
		<div role="group" {...props}>
			{Children.map(children, (child) =>
				cloneElement(child, {
					selected: child.props.value === value,
					onChange: handleChange,
				}),
			)}
		</div>
	);
};

BaseToggleButtonGroup.propTypes = {
	children: PropTypes.node.isRequired,
	value: PropTypes.any.isRequired,
	onChange: PropTypes.func.isRequired,
};

export const BaseToggleButton = ({
	children,
	selected,
	onChange,
	value,
	...props
}) => {
	const handleClick = () => {
		onChange(value);
	};

	return (
		<button
			onClick={handleClick}
			aria-pressed={selected}
			role="button"
			tabIndex={0}
			{...props}
		>
			{children}
		</button>
	);
};

BaseToggleButton.propTypes = {
	children: PropTypes.node.isRequired,
	selected: PropTypes.bool,
	onChange: PropTypes.func,
	value: PropTypes.any.isRequired,
};
