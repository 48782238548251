// import constants
import { FVY_DOMAIN, SITE, TCR_DOMAIN } from "@/config/constants";
import * as fvyConstants from "@/config/site/favy";
import * as tcrConstants from "@/config/site/thecreditreview";
// import selectors
import * as selectors from "@/selectors";
import { css } from "@emotion/react";
import PropTypes from "prop-types";
import { path } from "ramda";
import { useSelector } from "react-redux";

const styles = {
	desktopFilters: {
		baseStyles: css`
			display: flex;
			justify-content: left;
		`,
		domainLevelOverrides: {
			[TCR_DOMAIN]: {
				allVerticals: css`
					margin: 0;
				`,
				[tcrConstants.PERSONAL_LOANS_DEFAULT_PATH]: css`
					margin: 15px 0;
				`,
			},
			[FVY_DOMAIN]: {
				[fvyConstants.HAIR_GROWTH_PATH]: css`
					height: 64px;
					align-items: center;
					box-shadow: 0px 2px 2px #eaeaea;
				`,
			},
		},
	},
	innerDivFlex: {
		baseStyles: css`
			display: flex;
		`,
		domainLevelOverrides: {
			[TCR_DOMAIN]: {
				allVerticals: css`
					flex-wrap: nowrap;
					padding: 10px;

					@media (max-width: 767px) {
						flex-wrap: wrap;

						div:last-child {
							margin: 0;
						}
					}
				`,
				[tcrConstants.PERSONAL_LOANS_DEFAULT_PATH]: css`
					flex-wrap: wrap;
					padding: 0 15px 0 15px;
				`,
			},
		},
	},
};

const DesktopFilters = ({ children }) => {
	const vertical = useSelector(selectors.getVertical);

	return (
		<div
			css={[
				styles.desktopFilters.baseStyles,
				path(
					["desktopFilters", "domainLevelOverrides", SITE, "allVerticals"],
					styles,
				),
				path(
					["desktopFilters", "domainLevelOverrides", SITE, vertical],
					styles,
				),
			]}
		>
			<div
				className="col-xs-12 col-md-12 no-padding-right-sm no-padding-left-sm"
				css={[
					styles.innerDivFlex.baseStyles,
					path(
						["innerDivFlex", "domainLevelOverrides", SITE, "allVerticals"],
						styles,
					),
					path(
						["innerDivFlex", "domainLevelOverrides", SITE, vertical],
						styles,
					),
				]}
			>
				{children}
			</div>
		</div>
	);
};

export default DesktopFilters;

DesktopFilters.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
};
